<template>
  <div>
    <div
      v-if="isLoading"
      class="d-flex justify-content-center mb-1"
    >
      <b-spinner

        class="text-center"
        variant="secondary"
      />
    </div>

    <!-- Stats Card Vertical -->
    <b-row
      v-else
      class="match-height"
    >
      <b-col
        :md="columnWidth"
        sm="6"
      >
        <statistic-card-vertical
          icon="UsersIcon"
          :statistic="Number(data.totalCountDoctor).toLocaleString()"
          statistic-title="Bác sĩ"
          color="primary"
        />
      </b-col>
      <b-col
        v-if="!isMedicalFacilityRole || isHealthCentreFacility"
        :md="columnWidth"
        sm="6"
      >
        <statistic-card-vertical
          icon="UserCheckIcon"
          :statistic="Number(data.totalCountCustomer + data.totalCountMember).toLocaleString()"
          statistic-title="Người dân"
          color="primary"
        >
          <template
            slot="other"
          >
            <hr>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <h6 class="">
                  Tài khoản
                </h6>
                <h3 class="mb-0">
                  {{ Number(data.totalCountCustomer).toLocaleString() }}
                </h3>
              </div>
              <div>
                <h6 class="">
                  Thành viên GĐ
                </h6>
                <h3 class="mb-0">
                  {{ Number(data.totalCountMember).toLocaleString() }}
                </h3>
              </div>

            </div>
          </template>
        </statistic-card-vertical>
      </b-col>
      <b-col
        v-if="!isMedicalFacilityRole"
        :md="columnWidth"
        sm="6"
      >
        <statistic-card-vertical
          icon="HomeIcon"
          :statistic="Number(data.totalCountMedicalFacility).toLocaleString()"
          statistic-title="Cơ sở khám"
          color="primary"
        />
      </b-col>
      <b-col
        :md="columnWidth"
        sm="6"
      >
        <statistic-card-vertical
          icon="LifeBuoyIcon"
          :statistic="Number(data.totalCountMedicalFile).toLocaleString()"
          statistic-title="Phiên khám"
          color="primary"
        />
      </b-col>

    </b-row>
    <b-row v-if="canShowDashboardUser">
      <b-col cols="12">
        <user-report-card />
      </b-col>

    </b-row>

  </div>
</template>

<script>
import { BRow, BCol, BSpinner } from 'bootstrap-vue';

// eslint-disable-next-line import/no-cycle
import useJwt from '@/auth/jwt/useJwt';
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue';
import {
  getUserRole, getUserData, isHealthCentre, isMedicalFacility,
} from '@/auth/utils';
import UserReportCard from './UserReportCard.vue';

export default {
  components: {
    BRow,
    BCol,
    BSpinner,
    StatisticCardVertical,
    UserReportCard,
  },
  setup() {
    return { getUserRole };
  },
  data() {
    return {
      isLoading: false,
      data: {},
    };
  },
  computed: {
    columnWidth() {
      if (getUserRole().name === 'medical_facility') {
        if (isHealthCentre()) {
          return 4;
        }
        return 6;
      }
      return 3;
    },
    isMedicalFacilityRole() {
      return isMedicalFacility();
    },
    isHealthCentreFacility() {
      return isHealthCentre();
    },
    canShowDashboardUser() {
      const role = getUserRole();

      if (role.name === 'admin' || role.name === 'department_of_health') {
        return true;
      }
      if (role.name === 'medical_facility') {
        const userData = getUserData();
        if (userData && userData.medical_facility && userData.medical_facility.is_health_centre) {
          return true;
        }
      }
      return false;
    },
  },
  created() {
    this.isLoading = true;
    useJwt.getDashboard().then(response => {
      this.data = response.data;
      this.isLoading = false;
    }).catch(() => {
      this.isLoading = false;
    });
  },
};
</script>
